import React from 'react';
import { ResourceCenter } from 'cccisd-laravel-resources';
import style from './style.css';

const Resources = props => {
    function renderResourceTiles(data, renderDefault) {
        return <div className={style.resourceTiles}>{renderDefault(data)}</div>;
    }

    return <ResourceCenter handle={props.handle || 'default'} renderResourceTiles={renderResourceTiles} />;
};

export default Resources;
